<template>
  <div>
    <div class="card-toolbar mb-5">

      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="name">{{ $t('partners.full_name') }}</label>
              <input v-model="filters.full_name" type="text" id="name" class="form-control">
            </div>
            <div class="form-group col-md-6">
              <label for="email">{{ $t('partners.email') }}</label>
              <input v-model="filters.email" type="text" id="email" class="form-control">
            </div>
            <div class="form-group col-md-6">
              <label for="phone">{{ $t('partners.mobile') }}</label>
              <input v-model="filters.mobile" type="text" id="phone" class="form-control">
            </div>
            <div class="form-group col-md-6">
              <label for="from_date">{{ $t('partners.from_date') }}</label>
              <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
            </div>
            <div class="form-group col-md-6">
              <label for="to_date">{{ $t('partners.to_date') }}</label>
              <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('partners.country') }}</label>
              <div class="input-group">
                <multiselect
                    v-model="country"
                    :placeholder="$t('partners.country')"
                    label="name"
                    track-by="id"
                    :options="countries"
                    :multiple="false"
                    :taggable="false"
                    :show-labels="false"
                    :show-no-options="false"
                    :show-no-results="false"
                    @search-change="getCountries($event)"
                    :internal-search="false"
                >
                </multiselect>
              </div>
            </div>
            <div class="col-lg-6 mb-5">
              <label for="f_status">{{$t('status')}}</label>
              <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                <option value="">{{$t('all')}}</option>
                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
              </select>
            </div>
            <div class="form-group d-inline-flex col-md-6">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{ $t('search') }}</span>
                            </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{ $t('reset_search') }}</span>
                            </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-body">
<!--        <div class="row justify-content-end p-4">-->
<!--          <export-data :data-list="dataList" :fields="json_fields"-->
<!--                       :file-name="$t('partners.agents_management')"></export-data>-->
<!--        </div>-->

        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

          <template slot="mobile" slot-scope="props">
            <span class="tel-span">{{props.row.mobile}}</span>
          </template>
          <template slot="status" slot-scope="props">
<!--            v-if="$can('partners.update')"-->
            <button  @click="changeStatus(props.row.id, props.row.status)" class="btn btn-secondary m-btn m-btn--icon w-auto">
              {{$t('change_status')}} ({{ props.row.status_name }})
            </button>
          </template>
          <template slot="actions" slot-scope="props">
            <router-link :to="{name: 'partners.view', params:{id: props.row.id}}" class="btn btn-dark btn-sm ml-2 mr-2">
              {{$t('partners.details')}}
            </router-link>
          </template>
        </v-server-table>

      </div>
    </div>
    <!--end::User-->
    <b-modal ref="status-modal" size="md" hide-footer :title="$t('change_status')">
      <change-status-form @hide-modal="hideModal()"
                          :status-list="status_list"
                          :url="routeChangeStatus"
                          :current-id="innerId"
                          :current-status="statusId"
                          @handling-data="getDataAfterChangeStatus">
      </change-status-form>
    </b-modal>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ChangeStatusFrom from "@/view/content/forms/ChangeStatusForm";
export default {
  name: "index-partners",
  components: {'change-status-form': ChangeStatusFrom},
  data() {
    return {
      mainRoute: 'agents/partners',
      routeChangeStatus: 'agents/partner/change-status',
      mainRouteDependency: 'base/dependency',
      showAdvancedSearch: false,
      dataList: [],

      filter: {
        sortBy: 'id',
      },
      filters: {
        full_name: null,
        email: null,
        country_id: null,
        mobile: null,
        from_date: null,
        to_date: null,
        status: null,
      },
      columns: ['full_name', 'email', 'mobile', 'country_name','created_at', 'status', 'actions'],
      data: [],

      country: null,
      countries: [],
      status_list: [],

      innerId: null,
      statusId: null,
    }
  },
  computed: {
    // json_fields: function () {
    //   let fields = {};
    //   fields[this.$t('users.name')] = 'name';
    //   fields[this.$t('users.email')] = 'email';
    //   fields[this.$t('users.created_at')] = 'created_at';
    //
    //   return fields;
    // },
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          full_name: that.$t('partners.full_name'),
          email: that.$t('partners.email'),
          mobile: that.$t('partners.mobile'),
          country_name: that.$t('partners.country'),
          created_at: that.$t('partners.created_at'),
          status: that.$t('status'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'created_at'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(that.mainRoute, {..._params});

        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  watch: {
    "country": function (val) {
      if (val && val.id) {
        this.filters.country_id = val.id;
      }else {
        this.filters.country_id = null;
      }
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.partners")}]);
    this.getStatusList();
  },
  methods: {
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.full_name = null;
      this.filters.email = null;
      this.filters.country_id = null;
      this.filters.mobile = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.status = null;
      this.country = null;

      this.$refs.table.refresh();
    },

    getFetch() {
      this.$refs.table.refresh();
    },
    showModal() {
      this.$refs['status-modal'].show()
    },
    hideModal() {
      this.$refs['status-modal'].hide();
    },
    changeStatus(id, status) {
      this.innerId = id;
      this.statusId = status;
      this.showModal();
    },
    getDataAfterChangeStatus() {
      this.innerId = null;
      this.statusId = null;
      this.doFilter();
    },

    getCountries(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(this.mainRouteDependency+"/countries", {params: {filter: filter}}).then((response) => {
          this.countries = response.data.data;
        });
      }
    },
    getStatusList() {
        ApiService.get(this.mainRouteDependency+"/partner_status").then((response) => {
          this.status_list = response.data.data;
        });
    },


  },
};
</script>
