<template>
    <div>
        <div class="form-group row">
            <div class="col-lg-12 mb-5">
                <label>{{$t('status')}}</label>
                <select name="" id="status" v-model="data.status" class="custom-select" :class="validation && validation.tax_id ? 'is-invalid' : ''">
                    <option v-for="row in statusList" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.status[0] }}
                </span>
            </div>
        </div>
        <div class="card-footer pl-0 pr-0 pb-0">
            <div class="row">
                <div class="col-lg-6">
                    <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
                    <button type="reset" class="btn btn-secondary" @click="cancel">{{ $t('cancel') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import ApiService from "@/core/services/api.service";
    import Vue from "vue";

    export default {
        name: "form-change-status-modal",
        props: {
            hideModal: {type: Function},
            handlingData: {type: Function},
            statusList: {type: Array},
            url: {type: String},
            currentId: null,
            currentStatus: null,
            handlingStatus: {type: Function},
        },
        data() {
            return {
                data: {
                    status: null,
                    id: null,
                },
                validation: null,
            };
        },
        watch:{
            'data.status':function(val){
                if (val){
                    this.$emit('handling-status', this.data.status);
                }
            },
            currentStatus: function (val) {
                if (val){
                    this.data.status = this.currentStatus;
                    this.data.id = this.currentId ;
                }
            },
            currentId: function (val) {
                if (val){
                    this.data.status = this.currentStatus;
                    this.data.id = this.currentId ;
                }
            },
        },
        methods: {
            save() {
                this.changeStatus();
            },
            cancel() {
                this.$emit('hide-modal');
                this.resetAfterSave();
                this.finishedSave();
            },
            finishedSave(su_object) {
                this.$emit('handling-data', su_object);
            },
            changeStatus() {
                ApiService.patch(this.url + '/' + this.data.id, {
                    status: this.data.status,
                }).then((response) => {
                    this.$successAlert(response.data.message);
                    this.cancel();
                }).catch(error => {
                  Vue.prototype.$postStatus = true;
                    this.$errorAlert(error);
                });
            },
            resetAfterSave() {
                this.data.status = null;
                this.data.id = null;
            },
        },

        mounted() {
            this.data.status = this.currentStatus;
            this.data.id = this.currentId;

        },
    };
</script>